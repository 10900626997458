import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoWhite from "../images/LogoWhite.svg";
import HeaderLeft from "../images/header-left-image.svg";
import TopBg from "../images/top-bg.jpg";
import rightBg from "../images/right-bg.jpg";
import leftBg from "../images/left-bg.jpg";
import SignUp from "../images/signup.svg";
import Trophy from "../images/trophy.svg";
import Charity from "../images/charity.svg";
import Bg from "../images/bg.svg";
import Arrow from "../images/arrow.svg";
import LogoFoundationGray from "../images/logo-foundation-gray.svg";
import CurevedDot from "../images/cureved-dot.svg";
import FooterIconAddress from "../images/footer-icon-address.svg";
import FooterIconEmail from "../images/footer-icon-email.svg";
import Facebook from "../images/facebook.svg";
import Twitter from "../images/twitter.svg";
import Instagram from "../images/instagram.svg";
import Visa from "../images/visa.svg";
import Mastercard from "../images/mastercard.svg";
import LogoFoundationWhite from "../images/logo-foundation-white.svg";
import HeaderRight from "../images/nottingham-forest.png";
import SidebarLogo from "../images/logo-sidebar.svg";
import Cup from "../images/cup.svg";
import "../images/HeroBg.png";
import "../App.css";
import TestimonialSlider from "../components/testimonialSlider";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import apiClient from "../api.config";
import useDocumentTitle from "../components/useDocumentTitle";
import Moment from "moment";

import { toast, ToastContainer } from "react-toastify";
import TicketImg1 from "../images/ticket-img1.svg";
import TicketImg2 from "../images/ticket-img2.svg";
import TicketImg3 from "../images/ticket-img3.svg";
import NextArrow from "../images/next-arrow.svg";
import loaderSpinner from "../images/Loader.gif";
import Cross from "../images/cross.svg";
import FreeEntryIcon from "../images/free-entry-icon.svg";
import StripeCheckout from "react-stripe-checkout";
import { Globalvar } from "../components/global";
import StripeForm from "../components/stripeCheckout";
import { loadStripe } from "@stripe/stripe-js";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { Elements, useElements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
// const stripePromise = loadStripe('pk_test_51J0lzQDskTItAbcC8D9RpKIxhDgdkMJtytfO1VijW6FlEwjbba9L0u2vmDawgw9FaXfHe6W5X9GB3tx6HaUgvEb500ztI0saiC');

export default function Home() {
  useDocumentTitle("Home");
  const [scroll, setScroll] = useState(false);
  const [Sidebar, setSidebar] = useState(false);
  const [login, setLogin] = useState(secureLocalStorage.getItem("LogObject") ? secureLocalStorage.getItem("LogObject").loggedin : false);
  const [DrawData, setDrawData] = useState([]);

  const siteId = process.env.REACT_APP_SITE_ID;
  const APIURL = process.env.REACT_APP_API_URL;

  const [PrizeData, setPrizeData] = useState([]);
  const parser = new DOMParser();
  const [totalQuantities, setTotalQuantities] = useState(0);

  function logout() {
    secureLocalStorage.clear();
    axios.delete(`${process.env.REACT_APP_API_URL}/auth/logout`);
    setLogin(false);
  }

  const [paymentDetails, setPaymentDetails] = useState(false);
  const [SubscriptionPlan, setSubscriptionPlan] = useState(true);
  const [Freemodal, setFreemodal] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(true);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [amount, setAmount] = useState(10);
  const [entries, setEntries] = useState(11);
  const [consent1, setConsent1] = useState(false);
  const [consent2, setConsent2] = useState(false);
  const [consent3, setConsent3] = useState(false);
  const [userEmail, setEmail] = useState();
  const [fullName, setFullName] = useState("");
  const [userFirstName, setFirstName] = useState("");
  const [userLastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [age, setAge] = useState(0);
  const [SendDate, setSendDate] = useState();
  const [gender, setGender] = useState(1);
  const [prevDrawData, setPrevDrawData] = useState("");
  const [winnerData, setWinnerData] = useState([]);
  const [winnerModal, setWinnerModal] = useState(false);
  const [oneShow, setOneShow] = useState(false);
  const [isoneoff, setIsoneoff] = useState(false);
  const [isStripe, setStripe] = useState(false);
  const [UserID, setUserId] = useState(secureLocalStorage.getItem("LogObject") ? secureLocalStorage.getItem("LogObject").userID : "");
  const userData = secureLocalStorage.getItem("LogObject");

  const navigate = useNavigate();
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  function hideModal() {
    setOneShow(false);
  }
  Globalvar.amount = 10;
  Globalvar.entries = 11;

  function calculateTotalQuantity(PrizeData) {
    let sum = 0;
    {
      PrizeData.forEach((item) => {
        sum += item.prizeQuantity;
      });
    }
    return sum;
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);
  useEffect(() => {
    if (userData?.userID) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/users/${userData.userID}/data`, {
          withCredentials: true,
          credentials: "same-origin",
        })
        .then((res) => {
          setEmail(res.data.content.userEmail);
          setFullName(res.data.content.userFirstName + " " + res.data.content.userSurname);
          setFirstName(res.data.content.userFirstName);
          setLastName(res.data.content.userSurname);
        });
    }
  }, []);
  useEffect(() => {
    let selectedDrawID_ = "";
    axios.get(APIURL + "/sites/" + siteId + "/draws").then((res) => {
      let alldraw = res.data.content;
      // Ensure res.data.content is an array
      let activeDraw = "";
      if (Array.isArray(res.data.content)) {
        activeDraw = res.data.content.filter((item) => item.drawIsClosed !== 1 && item.drawIsVisible === 1 && item.drawIsActive === 1);
        console.log("filteredData", activeDraw);
      } else {
        console.error("res.data.content is not an array");
      }
      //let activeDraw = res.data.content.filter(item => item.drawIsClosed !== 1);
      let selectedDraw = {};
      if (activeDraw.length > 0) {
        selectedDraw = activeDraw[0];
      } else {
        selectedDraw = alldraw[0];
      }
      selectedDrawID_ = selectedDraw.drawID;
      setDrawData(selectedDraw);

      if (selectedDrawID_ !== "") {
        axios
          .get(APIURL + `/draws/${selectedDrawID_}/prizes`, {
            withCredentials: true,
            credentials: "same-origin",
          })
          .then((res) => {
            setPrizeData(res?.data?.content);
            setTotalQuantities(calculateTotalQuantity(res?.data?.content));
          });
      }
    });
  }, []);

  useEffect(() => {
    try {
      axios
        .get(APIURL + `/draws/host/9c71a8a6-a2f3-47a0-861c-911d2afc3976`, {
          withCredentials: true,
          credentials: "same-origin",
        })
        .then((res) => {
          let prev_data = "";
          if (Array.isArray(res.data.content.drawData)) {
            console.log("Winners Data", res.data.content.drawData);
            const prev_data = res.data.content.drawData
              .filter((item) => item.drawIsClosed === 1 && moment(item.drawEndDate) < moment())
              .sort((a, b) => new Date(a.drawEndDate) - new Date(b.drawEndDate))
              .reverse()[0];
            console.log("filteredDataPrev", prev_data);

            console.log("prev_data", prev_data);
            if (prev_data) {
              setPrevDrawData(prev_data);
              axios
                .get(APIURL + `/draws/winners/all/${prev_data.drawID}/1/1000`, {
                  withCredentials: true,
                  credentials: "same-origin",
                })
                .then((res) => {
                  setWinnerData(res?.data?.content?.winners);
                  console.log("winners", res?.data?.content?.winners);
                });
            }
          } else {
            console.error("res.data.content is not an array");
          }
        });
    } catch (error) {
      console.log("Error While Fetching: ", error);
    }
  }, []);

  const handleClick = (param, value, tickets) => {
    switch (param) {
      case "first":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(true);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(false);
        return;
      case "second":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(true);
        setIsActive3(false);
        setIsActive4(false);
        return;
      case "third":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(true);
        setIsActive4(false);
        return;
      case "fourth":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(true);
        return;
      default:
        setEntries(11);
        setAmount(10);
        setIsActive1(false);
        setIsActive2(true);
        setIsActive3(false);
        setIsActive4(false);
        return;
    }
  };

  function toPaymentDetails() {
    setSubscriptionPlan(false);
    setPaymentDetails(true);

    // Data Layer Push for Add to Cart event
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        user_id: userData?.userID ? userData?.userID : "Guest User",
        user_email: userData?.email ? userData?.email : "Guest User",
        items: [
          {
            item_name: DrawData.drawName,
            item_id: DrawData.drawID,
            price: amount,
            item_brand: "Sport",
            item_category:
              DrawData.drawCategoryID === 1
                ? "Fixed Cash Prize"
                : DrawData.drawCategoryID === 2
                ? "Split Cash Prize"
                : DrawData.drawCategoryID === 3
                ? "Experiential"
                : DrawData.drawCategoryID === 4
                ? "Hybrid"
                : "",
            item_variant: !isoneoff ? "Subscription" : "One-off",
            quantity: entries,
          },
        ],
      },
    });

    // Data Layer Push for Begin Checkout event
    window.dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        user_id: userData?.userID ? userData?.userID : "Guest User",
        user_email: userData?.email ? userData?.email : "Guest User",
        items: [
          {
            item_name: DrawData.drawName,
            item_id: DrawData.drawID,
            price: amount,
            item_brand: "Sport",
            item_category:
              DrawData.drawCategoryID === 1
                ? "Fixed Cash Prize"
                : DrawData.drawCategoryID === 2
                ? "Split Cash Prize"
                : DrawData.drawCategoryID === 3
                ? "Experiential"
                : DrawData.drawCategoryID === 4
                ? "Hybrid"
                : "",
            item_variant: !isoneoff ? "Subscription" : "One-off",
            quantity: entries,
          },
        ],
      },
    });
  }

  // else if (userFirstName == '') {
  //   toast("Please Enter the First Name", { type: "error" });
  // }
  // else if (userLastName == '') {
  //   toast("Please Enter the Last Name", { type: "error" });
  // }

  async function checkConsents() {
    const fn = fullName.split(" ");

    // const checkConsents = () => {
    if (!consent3) {
      toast("Please read and accept Terms & Conditions", { type: "error" });
    } else if (fullName === "") {
      toast.error(`Please Enter Your valid Full Name`);
    } else if (!fn[0] || fn[0] === "") {
      toast.error(`Please Enter Your First and last Name`);
    } else if (!fn[1] || fn[1] === "") {
      toast.error(`Please Enter Your last Name`);
    } else if (userEmail == "") {
      toast("Please Enter the Email Address", { type: "error" });
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(userEmail)) {
      toast.error(`Invalid Email`);
    }
    // else if (dob === '') {
    //   toast.error(`Please Enter Your Date of birth`);
    // }
    // else if (dob.length < 10) {
    //   toast.error(`Please Enter Your valid Date of birth`);
    // }
    // else if(age <= 18) {
    //   console.log('age + ' + age);
    //   toast.error(`Sorry. Only users over the age of eighteen are allowed to enter this Prize Draw" then we're good to go live!`);
    // }
    else {
      setStripe(true);
      setOneShow(true);

      //ValidateEmail
      // const POSTCODER_KEY =
      //   process.env.POSTCODER_API_KEY || "PCWW5-FVLAY-K6FGB-KSTYM";
      // await axios
      //   .get(`https://ws.postcoder.com/pcw/${POSTCODER_KEY}/email/${userEmail}`)
      //   .then((res) => {
      //     console.log("res.data", res.data);
      //     if (res.data.valid) {
      //       setStripe(true);
      //       setOneShow(true);
      //     } else {
      //       toast.error(`Invalid Email`);
      //     }
      //   });
    }
    // }
  }

  return (
    <>
      <header className={scroll ? "home site-header font-face-sh py-4 position-sticky top-0 bg-branding-1 z-index-9" : "home site-header font-face-sh py-4"}>
        <div className="container">
          <div className="inner-header row d-flex align-items-center">
            <div className="logo col-sm-6 col-md-6 col-lg-6 col-6">
              <Link to={"/"}>
                <img className="start-0" src={LogoWhite} alt="logo-white" style={{ width: "162px" }} />
              </Link>
            </div>
            <div className="desktop-menu col-md-6 d-flex justify-content-end align-items-center">
              {login ? (
                <>
                  <DropdownButton align="end" title="Account" id="dropdown-menu-align-end" className="text-white transparent">
                    <Dropdown.Item eventKey="1" href="/account">
                      My Impact
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="2" href="/account/prizes">
                      Prizes
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="3" href="/account/subscription">
                      Manage Subscription
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="6" href="/account/payment-method">
                      Manage Payment
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="4" href="/account/details">
                      Settings
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="5" onClick={() => logout()}>
                      Log out
                    </Dropdown.Item>
                  </DropdownButton>
                  <Link
                    to={"/subscription-plan"}
                    className="py-3 px-5 border border-2 border-white rounded-pill bg-branding-1 text-white text-decoration-none fs-6 ms-4 lh-1 font-face-sh"
                  >
                    Enter The Draw
                  </Link>
                </>
              ) : (
                <>
                  <Link to={"/login"} className="text-white text-decoration-none fs-6 font-face-sh-bold">
                    Login
                  </Link>
                  <Link to={"/signup"} className="py-3 px-5 border border-2 border-white rounded-pill text-white text-decoration-none fs-6 ms-4 lh-1 font-face-sh-bold">
                    Join Now
                  </Link>
                </>
              )}
            </div>
            <div className="col-sm-6 col-md-6 col-6 mobile-menu justify-content-end d-flex align-items-center d-none">
              <div className={Sidebar ? "MenuButton_rootActive" : "MenuButton_root"} onClick={() => setSidebar(!Sidebar)}>
                <div className={Sidebar ? "MenuButton_firstLineActive" : "MenuButton_firstLine"}></div>
                <div className={Sidebar ? "MenuButton_secondLineActive" : "MenuButton_secondLine"}></div>
                <div className={Sidebar ? "MenuButton_thirdLineActive" : "MenuButton_thirdLine"}></div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="mySidebar"
          className={
            Sidebar
              ? "open sidebar bg-light h-100 top-0 end-0 position-fixed d-flex flex-column ease-in-out overflow-hidden py-4 px-4"
              : "sidebar h-100 w-0 end-0 top-0 bg-light position-fixed pt-2 ease-in-out overflow-hidden"
          }
        >
          <div className="logo-red col-md-12">
            <Link to={"/"}>
              <img className="start-0" src={SidebarLogo} alt="logo-white" style={{ width: 180 }} />
            </Link>
          </div>
          <div className="mobile-menu-nav col-md-12 mt-5 d-flex flex-column">
            {login ? (
              <>
                <Link
                  to={"/subscription-plan"}
                  className="text-center mb-3 py-3 px-5 border border-2 border-white rounded-pill bg-branding-1 text-white text-decoration-none fs-6 lh-1 font-face-sh"
                >
                  Enter The Draw
                </Link>
                <div className="fs-6 mb-1" style={{ color: "#999" }}>
                  <strong>Account</strong>
                </div>
                {/* <Link to={"/login"} className='black-clr text-decoration-none fs-5 font-face-sh-bold branding-1-hover'>Login</Link>
          <Link to={"/signup"} className='py-3 px-5 border border-2 border-branding-1 rounded-pill black-clr text-decoration-none branding-1-hover fs-5 mt-3 w-75 text-center lh-1'>Join Now</Link> */}
                <Link to={"/account"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  My Impact
                </Link>
                <Link to={"/account/prizes"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Prizes
                </Link>
                <Link to={"/account/subscription"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Manage Subscription
                </Link>
                <Link to={"/account/payment-method"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Manage Payment
                </Link>
                <Link to={"/account/details"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Settings
                </Link>
                <hr />
                <Link onClick={logout} to={"/"} className="black-clr text-decoration-none fs-5 branding-1-hover">
                  Log Out
                </Link>
              </>
            ) : (
              <>
                <Link to={"/login"} className="black-clr text-decoration-none fs-5 font-face-sh-bold branding-1-hover">
                  Login
                </Link>
                <Link
                  to={"/signup"}
                  className="py-3 px-5 border border-2 border-branding-1 rounded-pill black-clr text-decoration-none branding-1-hover fs-5 mt-3 w-75 text-center lh-1"
                >
                  Join Now
                </Link>
              </>
            )}
          </div>

          <div className="col-sm-12 col-md-12 col-lg-3 mt-auto">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 branding-1">
                <p className="mt-4 pt-2 font-face-sh-bold">Follow us</p>
                <div className="d-flex">
                  <a className="black-clr text-decoration-none" href="https://www.facebook.com/ChelseaFC" target="_blank">
                    <img className="me-3" src={Facebook} alt="Facebook" style={{ width: 24 }} />
                  </a>
                  <a className="black-clr text-decoration-none" href="https://twitter.com/CFCFoundation" target="_blank">
                    <img className="me-3" src={Twitter} alt="Twitter" style={{ width: 24 }} />
                  </a>
                  <a className="black-clr text-decoration-none" href="https://www.instagram.com/chelseafc/" target="_blank">
                    <img className="me-3" src={Instagram} alt="Instagram" style={{ width: 24 }} />
                  </a>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 branding-1">
                <p className="mt-4 pt-2" style={{ fontSize: 14 }}>
                  Lucky Blues is operated by{" "}
                  <a className="text-decoration-none branding-1 branding-1-hover" href="https://playfundwin.com/" target="_blank">
                    Play Fund Win
                  </a>
                </p>
                <div className="d-flex font-face-sh-bold" style={{ fontSize: 14 }}>
                  <Link className="branding-1 text-decoration-none pe-5 branding-1-hover" to={"/terms-conditions"}>
                    Terms & Conditions
                  </Link>
                  <Link className="branding-1 text-decoration-none branding-1-hover" to={"/privacy-policy"}>
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="hero-section herosec-img font-face-sh py-sm-" style={{ marginTop: "-110px", padding: "170px 0 200px" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-7 col-lg-7">
              <h1 className="text-white lh-1 mb-4 display-1 font-face-sh-bold">
                IT’S A <br />
                WIN-WIN!
              </h1>
              <h3 className="text-white w-75 wt-100 fs-5 lh-2 fw-normal pb-md-5 mb-5 mr-0">
                Win big cash & club prizes every home match day draw! In support of The Chelsea Foundation.
              </h3>
              <Link to={"/signup"} className="btn text-uppercase bg-branding-green border-0 w-50 rounded-pill py-3 mt-5 lh-1 desktop-btn text-light ">
                Join Now
              </Link>
            </div>
            <div className="col-sm-12 col-md-5 col-lg-5 hero-right-block d-flex justify-content-end">
              <div className="match-card w-100 ms-2">
                <div className="matchcard-header px-4 py-3 d-flex align-items-center justify-content-between bg-branding-1">
                  <p className="text-white fs-5 m-0">In support of</p>
                  <img className="start-0" style={{ width: 178 }} src={LogoFoundationWhite} alt="LogoFoundationWhite" />
                </div>
                <div className="matchcard-logosblock bg-white text-center px-5 pt-5" style={{ borderBottomLeftRadius: 90 }}>
                  <p className="branding-2 fs-4">Winners next announced:</p>
                  <div className="d-flex align-items-center justify-content-between py-5 pb-5">
                    <img className="start-0" style={{ width: 100 }} src={HeaderLeft} alt="HeaderLeft" />
                    <h2 className="branding-2 lh-1 display-3 font-face-sh-bold">VS</h2>
                    <img
                      className="start-0"
                      style={{ width: 100 }}
                      src={DrawData.drawContent ? (JSON.parse(DrawData.drawContent).vsLogo ? JSON.parse(DrawData.drawContent).vsLogo : HeaderRight) : loaderSpinner}
                      alt="vs Logo"
                    />
                  </div>
                  <p className="branding-2 fs-4 pt-2 m-0 pb-5">{DrawData?.drawEndDate ? Moment(DrawData.drawEndDate).format("LLL") : "-----, /--/--/-- 00:00PM"}</p>
                  <span
                    className="text-white fs-6 text-center bg-branding-1 px-3 pt-2 pb-2 d-inline-block fw-mediumbold"
                    onClick={() => {
                      setWinnerModal(true);
                    }}
                    style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, cursor: "pointer" }}
                  >
                    <img src={Cup} /> View Previous Winners
                  </span>
                </div>
              </div>
            </div>
            <div className="mobile-bottom d-none">
              <Link to={"/signup"} className="btn bg-branding-green text-uppercase border-0 w-50 rounded-pill py-3 mt-5 lh-1 text-white">
                Join Now
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Modal show={winnerModal} onHide={() => setWinnerModal(false)} size="xl" centered>
        <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
        <Modal.Body>
          <section className="previous-winners_section font-face-sh mb-3">
            <div className="container">
              <div className="previous-winners_title text-center">
                <h4 className="text-white bg-branding-1 fs-5 text-center py-1 px-3 rounded-pill d-inline-block font-face-sh-bold">
                  {Moment(prevDrawData.drawStartDate).format("MMMM DD, YYYY")} - {Moment(prevDrawData.drawEndDate).format("MMMM DD, YYYY")}
                </h4>
                <h2 className="display-6 text-uppercase text-center border-bottom pb-2 mb-3 font-face-sh-bold black-clr">Previous Winners</h2>
              </div>

              <div className="text-center">
                {winnerData.length ? (
                  <div className="row justify-content-center">
                    {winnerData.length === 0 ? (
                      <span className="fs-5 text-center">Winners Not Announced Yet !</span>
                    ) : (
                      winnerData.map((item) => {
                        return (
                          <div className="col-md-4 winner-col px-2">
                            <div className="winner-col-inner rounded-3 px-3 py-1 mb-3" style={{ backgroundColor: "#f8f8f8" }}>
                              <div className="d-flex align-items-center">
                                <div className="win-icon">
                                  <img src={require("../images/winner-icon.png")} alt="winner" style={{ width: 35, marginTop: "-5px" }} />
                                </div>
                                <div className="win-info ms-2 text-start">
                                  <h5 className="mb-0 fs-6">
                                    <strong>
                                      {item.drawUserFirstName} {item.drawUserSurname}
                                    </strong>
                                  </h5>
                                  <h5 className="mb-0 mt-1" style={{ fontSize: 14 }}>
                                    {item.drawPrizeName}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                ) : (
                  <img src={loaderSpinner} style={{ width: 60, margin: "0 auto" }} />
                )}
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>

      <section className="stories-section-title font-face-sh mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="branding-1 display-4 text-uppercase text-center mt-md-5 font-face-sh-bold" style={{ paddingBottom: 170 }}>
                Guaranteed Half-time Winners
              </h2>
            </div>
          </div>
        </div>
        <div className="stories-section bg-branding-1">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="stories-container p-3 mx-auto light-border position-relative" style={{ width: 771, borderRadius: 20, marginTop: -125 }}>
                  <TestimonialSlider />
                  <img className="mx-auto position-absolute start-0 end-0 curve-dot" src={CurevedDot} alt="CurevedDot" style={{ width: 92, bottom: -23 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='prizes-section font-face-sh py-5 position-relative'>
      <div className='container'>
        <div className='row'>
          <div className='prizes-section_wrapper col-12 py-md-5' style={{marginTop: '50px'}}>
            <h2 className='text-white display-4 text-uppercase text-center mt-md-5 font-face-sh-bold'>Win Prizes Every Month</h2>
            <p className='text-white fs-5 text-center' style={{marginBottom: '125px'}}>New cash & club prizes to be won every month. Winners informed via email.</p>
            <div className='prizes-section_container mx-auto bg-branding-1 pb-3' style={{width: 1030, borderRadius: 24}}>
              <div className='prizes-section_blockcontainer_sec d-flex' style={{borderRadius: 10}}>
                <div className='prizes-section_blockcontainer p-2 bg-white mx-md-5 w-100' style={{borderRadius: 10, marginTop: '-90px'}}>
                  <div className='prizes-section_block text-center bg-position pt-4 pb-4' style={{backgroundImage: `url(${TopBg})`, borderRadius: 10}}>

                    <h2 className='text-yellow display-1 lh-1 m-0 font-face-sh-bold'>£2,495</h2>

                    <h3 className='text-white fs-2 m-0 font-face-sh-bold'>GUARANTEED HALF-TIME CASH PRIZES</h3>
                  </div>
                </div>
              </div>
              <div className='prizes-section_cupholder text-center w-100 position-relative d-flex align-items-center justify-content-center'>
                <div className='prizes-section_cupholder_inner d-flex align-items-center justify-content-center position-absolute start-0 end-0 bottom-0' style={{top: 26}}>
                  <div className='prizes-section_cup bg-branding-1 rounded-pill mx-3 d-flex justify-content-center' style={{width: 48, height: 48, marginTop: -11}}>
                    <img className='' src={Cup} alt="Cup" style={{width: 24}}/>
                  </div>
                  <div className='prizes-section_cup bg-branding-1 rounded-pill mx-3 d-flex justify-content-center' style={{width: 64, height: 64}}>
                    <img className='' src={Cup} alt="Cup" style={{width: 32}}/>
                  </div>
                  <div className='prizes-section_cup bg-branding-1 rounded-pill mx-3 d-flex justify-content-center' style={{width: 48, height: 48, marginTop: -11}}>
                    <img className='' src={Cup} alt="Cup" style={{width: 24}}/>
                  </div>
                </div>
              </div>
              <div className='prizes-section-prizes_container d-flex align-items-center text-center mx-5 mt-4'>
                <div className='col-md-6 pe-2' style={{borderRadius: 10}}>
                  <div className='prizes-section_prizes bg-white p-2' style={{borderRadius: 10}}>
                    <div className='prizes-section_prizes_bg branding-1-light-bg py-5 bg-position' style={{backgroundImage: `url(${leftBg})`, borderRadius: 10}}>
                      <h3 className='text-white fs-3 text-uppercase m-0 py-md-2 px-4 font-face-sh-bold'>15+ Winners</h3>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 ps-2' style={{borderRadius: 10}}>
                  <div className='prizes-section_prizes bg-white p-2' style={{borderRadius: 10}}>
                    <div className='prizes-section_prizes_bg branding-1-light-bg py-5 bg-position' style={{backgroundImage: `url(${rightBg})`, borderRadius: 10}}>
                      <h3 className='text-white fs-3 text-uppercase m-0 py-md-2 px-2 font-face-sh-bold'>Exclusive Club Prizes</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}

      <section className="prizes-section font-face-sh py-5 position-relative">
        <div className="container">
          <div className="row">
            <div className="prizes-section_wrapper col-12 py-md-5" style={{ marginTop: "50px" }}>
              <h2 className="text-white display-4 text-uppercase text-center mt-md-5 font-face-sh-bold">Win Prizes Every Month</h2>
              <p className="text-white fs-5 text-center" style={{ marginBottom: "78px" }}>
                New cash & club prizes to be won every month. Winners informed via email.
              </p>
              <div className="prizes-section_container mx-auto bg-branding-1 pb-3" style={{ width: 1030, borderRadius: 24 }}>
                <div className="prizes-section-prizes_container row align-items-center justify-content-center text-center mx-5 mt-4">
                  {PrizeData?.map((item) => {
                    return (
                      <div className="col-md-6 pe-2" style={{ borderRadius: 10 }}>
                        <div className="prizes-section_prizes bg-white p-2" style={{ borderRadius: 10 }}>
                          <div
                            className="prizes-section_prizes_bg branding-1-light-bg py-5 bg-position"
                            style={{ backgroundImage: `url(${item.prizeImageURL})`, borderRadius: 10 }}
                          >
                            <h3
                              className="text-white fs-3 text-uppercase m-0 py-md-2 px-4 font-face-sh-bold"
                              dangerouslySetInnerHTML={{ __html: parser.parseFromString(`${item.prizeName}`, "text/html").body.textContent }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="prizes-section_cupholder text-center w-100 position-relative d-flex align-items-center justify-content-center">
                  <div className="prizes-section_cupholder_inner d-flex align-items-center justify-content-center position-absolute start-0 end-0 bottom-0" style={{ top: 26 }}>
                    <div className="prizes-section_cup bg-branding-1 rounded-pill mx-3 d-flex justify-content-center" style={{ width: 48, height: 48, marginTop: -11 }}>
                      <img className="" src={Cup} alt="Cup" style={{ width: 24 }} />
                    </div>
                    <div className="prizes-section_cup bg-branding-1 rounded-pill mx-3 d-flex justify-content-center" style={{ width: 64, height: 64 }}>
                      <img className="" src={Cup} alt="Cup" style={{ width: 32 }} />
                    </div>
                    <div className="prizes-section_cup bg-branding-1 rounded-pill mx-3 d-flex justify-content-center" style={{ width: 48, height: 48, marginTop: -11 }}>
                      <img className="" src={Cup} alt="Cup" style={{ width: 24 }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="how-it-works_section font-face-sh bg-position py-5" style={{ backgroundImage: `url(${Bg})`, borderRadius: 10 }}>
        <div className="container-2 bg-white p-5 shadow-lg">
          <div className="how-it-works_title text-center">
            <h2 className="display-5 text-uppercase text-center border-bottom mb-5 mt-2 pb-2 d-inline-block font-face-sh-bold branding-1">How It Works</h2>
          </div>
          <div className="row">
            <div className="col-md-4 text-center">
              <img src={SignUp} alt="signup" />
              <h2 className="mt-2">
                <span className="play-icon position-relative">Play</span>
              </h2>
              <p className="black-clr fs-5 text-center lh-1 mt-1">Set up a monthly donation online and be entered into every draw.</p>
            </div>
            <div className="col-md-4 text-center">
              <img src={Charity} alt="Charity" />
              <h2 className="mt-2">
                <span className="fund-icon position-relative">Fund</span>
              </h2>
              <p className="black-clr fs-5 text-center lh-1 mt-1">Become a supporter of Chelsea FC Foundation’s community work.</p>
            </div>
            <div className="col-md-4 text-center">
              <img src={Trophy} alt="Trophy" />
              <h2 className="mt-2">
                <span className="win-icon position-relative">Win</span>
              </h2>
              <p className="black-clr fs-5 text-center lh-1 mt-1">Stay tuned for half-time when winners are informed via email!</p>
            </div>
          </div>
        </div>
      </section>

      <section className="charity_section font-face-sh mb-5 pb-5">
        <div className="container-2">
          <div className="charity_title text-center py-5 px-md-5">
            <h2 className="branding-1 display-4 text-uppercase text-center px-md-5 lh-1 font-face-sh-bold">Chelsea FC Foundation</h2>
            <p className="branding-1 fs-5 lh-1 mt-4 w-50 m-auto">Using the power of football and sport to motivate, educate and inspire.</p>
            <p className="branding-1 fs-6  lh-1 mt-5">Read about our work</p>
            <div style={{ marginTop: -35 }}>
              <img src={Arrow} alt="Arrow" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 pe-5">
              <p className="branding-3 fs-5 lh-2">
                As one of the world’s leading football social responsibility programmes the Chelsea Foundation uses the power of football and sport to motivate, educate and
                inspire. We believe that the power of football can be harnessed to support communities and individuals both at home and abroad.
              </p>
              <p className="branding-3 fs-5 lh-2">
                On top of our outstanding football development programmes, the Chelsea Foundation works on a broad range of initiatives focusing on employment, education, social
                deprivation, crime reduction, youth offending and much more.
              </p>
              <Link to={"/signup"} className="btn bg-branding-1 shadow-lg text-white border-0 w-50 mb-5 rounded-pill py-3 mt-5 lh-1">
                Find out more
              </Link>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="row">
                <div className="col-sm-6 col-md-12 col-lg-6 col-6 pe-4">
                  <img className="w-100 rounded-3" src={require("../images/charity4.jpg")} alt="charity-image1" />
                </div>
                <div className="col-sm-6 col-md-12 col-lg-6 col-6 ps-4">
                  <img className="w-100 rounded-3" style={{ objectFit: "cover", height: "100%" }} src={require("../images/charity-image3.jpg")} alt="charity-image2" />
                </div>
              </div>
              <div className="row pt-4 mt-3">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <img className="w-100 rounded-3" src={require("../images/chelsea-header-2.jpg")} alt="charity-image3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        {/* <div className='draw-entry position-fixed px-3 py-3 bottom-0 bg-branding-1 w-100' style={{zIndex : 9999999 }}> */}

        {!paymentDetails && SubscriptionPlan ? (
          <section className="subscription-plan-home font-face-sh draw-entry position-fixed px-3 py-3 bottom-0 bg-branding-1 w-100" style={{ zIndex: 999 }}>
            <div className="row align-items-center">
              <div className="col-sm-9 col-md-9 col-lg-9 col-9">
                <h1 className="text-light">Enter the draw</h1>
                <p className="text-light">How many tickets would you like to buy?</p>
              </div>
              <div
                className="col-sm-3 col-md-3 col-lg-3 col-3 text-center bg-branding-2"
                style={{ backgroundColor: "#000", borderBottomLeftRadius: 70, borderBottomRightRadius: 70, padding: "14px 0px", marginTop: "-23px" }}
              >
                <h4 className="text-light">Total</h4>
                <h4 className="text-light d-flex justify-content-center align-items-center">
                  <div className="subscription-plan-price_currency text-light fs-2 font-face-sh-bold mt-4 me-1">£</div>
                  <div className="subscription-plan-price_amount text-light display-3 font-face-sh-bold">{amount}</div>
                </h4>
              </div>
            </div>
            <div className="subscription-plan-container mx-auto" style={{ maxWidth: 700 }}>
              <div
                className={Freemodal ? "steps-subscription-plan px-0 position-relative pt-5 d-none" : "steps-subscription-plan px-0 position-relative d-block"}
                style={{ zIndex: 999 }}
              >
                <div className="ticket-entry">
                  <div className="form-check form-switch">
                    <input checked={isoneoff} onChange={() => setIsoneoff(!isoneoff)} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                    <label className="form-check-label text-white" htmlFor="flexSwitchCheckDefault">
                      {!isoneoff ? "Tick to make a one-off entry into the next draw" : "untick to make a Subscription entry into the next draw"}
                    </label>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-6 col-md-6 col-lg-6 col-6">
                      <div className="step-subscription-plan-selector home-data">
                        <ul className="d-flex unstyled list-unstyled gap-3">
                          <li className={isActive1 ? "active" : ""} onClick={() => handleClick("first", 5, 5)}>
                            <strong
                              className="ticket-price d-flex branding-1 justify-content-center align-items-center branding1 rounded-circle"
                              style={{ backgroundColor: "#fff", width: 42, height: 42, alignItems: "center", justifyContent: "center", display: "flex" }}
                            >
                              <div className="subscription-price fs-5 d-none">
                                <strong>£5</strong>
                              </div>
                              <div className="subscription-period fs-6 d-none">{!isoneoff ? "/month" : ""}</div>5
                            </strong>
                          </li>
                          <li className={isActive2 ? "position-relative active" : "position-relative"} onClick={() => handleClick("second", 10, 11)}>
                            <strong
                              className="ticket-price d-flex branding-1 justify-content-center align-items-center branding1 rounded-circle"
                              style={{ backgroundColor: "#fff", width: 42, height: 42, alignItems: "center", justifyContent: "center", display: "flex" }}
                            >
                              <div className="subscription-price fs-5 d-none">
                                <strong>£10</strong>
                              </div>
                              <div className="subscription-period fs-6 d-none">{!isoneoff ? "/month" : ""}</div>
                              11
                            </strong>
                          </li>
                          <li className={isActive3 ? "active" : ""} onClick={() => handleClick("third", 20, 25)}>
                            <strong
                              className="ticket-price d-flex branding-1 justify-content-center align-items-center branding1 rounded-circle"
                              style={{ backgroundColor: "#fff", width: 42, height: 42, alignItems: "center", justifyContent: "center", display: "flex" }}
                            >
                              <div className="subscription-price fs-5 d-none">
                                <strong>£20</strong>
                              </div>
                              <div className="subscription-period fs-6 d-none">{!isoneoff ? "/month" : ""}</div>
                              25
                            </strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 col-6">
                      <div className="step-subscription-button text-center d-flex justify-content-center">
                        <button
                          onClick={() => toPaymentDetails(true)}
                          className="btn bg-branding-green border-0 rounded-pill py-2 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                          style={{ width: 400, height: 48 }}
                        >
                          <span>{!isoneoff ? "Subscribe" : "Buy Now"}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Freemodal ? "free-entries-modal d-block position-relative" : "free-entries-modal d-none"}>
                <div className="free-entry-modal_title_block mb-5 pb-5">
                  <img
                    className="cross bg-white rounded-3 p-1 position-absolute end-0 top-0"
                    src={Cross}
                    style={{ width: 56, boxShadow: "0 4px 16px rgb(20 19 38 / 30%)", cursor: "pointer" }}
                    onClick={() => setFreemodal(!Freemodal)}
                  />
                  <h2 className="free-entry-modal_title text-light font-face-sh-bold display-6 text-center mb-3 mt-5">Free Prize Draw</h2>
                  <p className="free-entry-modal_text text-light text-center fs-5 mx-auto mt-5" style={{ maxWidth: 375 }}>
                    While we encourage contributions to achieve our mission, we understand not everyone may be in a position to contribute.
                  </p>
                </div>
                <div className="free-entry-modal_description_block mx-auto mt-5 p-4 bg-white d-flex" style={{ maxWidth: 375 }}>
                  <div className="free-entry-icon">
                    <img src={FreeEntryIcon} style={{ width: 24 }} />
                  </div>
                  <div className="free-entry-description ps-4 fs-14 lh-1">
                    <p>For 5 non-donation entries to the prize draw please post the following information:</p>
                    <p>
                      * First Name
                      <br />
                      * Last Name
                      <br />* Email
                    </p>
                    <p>
                      to: PFW Holdings Ltd
                      <br />
                      Play Fund Win Office #300,
                      <br />4 Blenheim Court,
                      <br />
                      Peppercorn Close,
                      <br />
                      Peterborough,
                      <br />
                      PE1 2DU
                      <br />
                      <br />
                      You will be entered into the prize draws for the month in which we receive your post entries.
                      <br />
                      <br />
                      Note: This will not result in any contributions to the Foundation
                    </p>
                    <div className="emoji-icon text-center pe-5 mt-4 pt-2">
                      <img className="mx-auto" src={require("../images/emoji_icon.png")} style={{ width: 66 }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : paymentDetails && !oneShow ? (
          <section
            className="personal-details font-face-sh py-5 position-fixed"
            style={{ backgroundColor: "#fff", left: 0, right: 0, top: 0, bottom: 0, zIndex: 999999, overflow: "scroll" }}
          >
            <a className="black-clr text-decoration-none" href="/">
              <img className="mx-3" src={require("../images/back.png")} style={{ width: 32 }} />
            </a>
            <div className="subscription-plan-container mx-auto" style={{ maxWidth: 780 }}>
              <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-12 step-user-details pe-5">
                  <div className="step-plan_step branding-1 rounded-pill text-center mx-auto" style={{ backgroundColor: "#f7f7fd", width: 112, height: 45, lineHeight: "45px" }}>
                    <strong>Step 2 of 2</strong>
                  </div>
                  {/* <h2 className='text-black font-face-sh-bold mt-3 text-center'>Payment details</h2> */}
                  <p className="text-center" style={{ color: "#808080", fontSize: "18px" }}>
                    {isoneoff ? "" : "We’ll take your subscription amount each month."}
                  </p>
                  <form method="post">
                    {/* <label className='mb-2 ps-3' style={{color: '#808080', fontSize: 15}}>Payment method</label> */}
                    {/* <div className='text-light bg-branding-1 rounded-pill text-center mb-4' style={{width: 112, height: 45, lineHeight: '45px', fontSize: 14}}>Bank card</div> */}
                    {/* <input type="text" name="cardnumber" id="cardnumber" placeholder="Card number" className="input mb-3" /> */}
                    <div className="form-checkbox">
                      <ul className="checkbox-list list-unstyled">
                        <li className="checkbox-item mb-4">
                          <label className="input-checkbox d-flex">
                            <input checked={consent1} onChange={() => setConsent1(!consent1)} type="checkbox" name="consentId1" value="1" />
                            <span className="input-checkbox_check"></span>
                            <span className="input-checkbox-label">Yes, I’d like to receive updates about Lucky Blues via email.</span>
                          </label>
                        </li>
                        <li className="checkbox-item">
                          <label className="input-checkbox d-flex">
                            <input checked={consent2} onChange={() => setConsent2(!consent2)} type="checkbox" name="consentId2" value="10" />
                            <span className="input-checkbox_check"></span>
                            <span className="input-checkbox-label">Yes, Chelsea FC Foundation can contact me about their work via email.</span>
                          </label>
                        </li>
                        <li className="checkbox-item">
                          <label
                            className="step-payment-details_consent_description mt-3 input-checkbox d-flex"
                            style={{ backgroundColor: "rgb(198,29,35,.1)", color: "#302d51", padding: "14px 18px", borderRadius: 20, fontSize: 13 }}
                          >
                            <input checked={consent3} onChange={() => setConsent3(!consent3)} type="checkbox" name="consentId3" value="10" />
                            <span className="input-checkbox_check"></span>
                            <span>
                              By clicking on Confirm you agree to Lucky Blues Draw{" "}
                              <Link to={"/terms-conditions"} style={{ color: "#302d51" }}>
                                Terms & Conditions of Use
                              </Link>
                              ,{" "}
                              <Link to={"/privacy-policy"} style={{ color: "#302d51" }}>
                                Privacy Policy
                              </Link>{" "}
                              and that you <strong>are at least 18 years old.</strong>
                            </span>
                          </label>
                        </li>
                        <li className="checkbox-item pt-4 pb-0">
                          <input
                            type="text"
                            name="fullName"
                            id="fullName"
                            placeholder="Full Name"
                            value={fullName}
                            onChange={(event) => setFullName(event.target.value)}
                            className="input mb-3"
                          />
                          {/* <input type="text" id="firstname" value={userFirstName} onChange={(event) => setFirstName(event.target.value)} placeholder="Enter first name" className="input" style={{ display: 'block' }} />
                                                    <input type="text" id="lastname" value={userLastName} onChange={(event) => setLastName(event.target.value)} placeholder="Enter last name" className="input" style={{ display: 'block' }} /> */}
                          <input
                            type="email"
                            id="email"
                            value={userEmail}
                            onChange={(event) => setEmail(event.target.value)}
                            placeholder="Email address"
                            className="input"
                            style={{ display: "block" }}
                          />
                          {/* <input type="tel" value={dob} onInput={(event) => dateSet(event.target.value, event)} name="dateOfBirth" id="dateOfBirth" placeholder="Date of birth (DD/MM/YYYY)" className="input mb-2" /> */}
                          {/* <div className='dob-field'>
                                                      <input type="date" id={"donb"} value={dob} onFocus={(e)=> {e.currentTarget.type = "date";e.currentTarget.focus();}} onInput={(event) => { setDob(event.target.value, event); dateSet(event.target.value, event)}} name="dateOfBirth" className='input mb-2' /> 
                                                      <label className='mb-2' htmlFor='donb' style={{ color: '#818d9d', fontSize: 16 }}>{dob?dob:"Date of birth (DD/MM/YYYY)"}</label>
                                                    </div> */}
                          <div className="form-radio-box mb-4">
                            <label className="mb-2" style={{ color: "#808080", fontSize: 14 }}>
                              Gender
                            </label>
                            <ul className="radio-list list-unstyled d-flex gap-3">
                              <li className="radio-item">
                                <label className="input-radio">
                                  <input type="radio" name="gender" value="1" defaultChecked onClick={() => setGender(1)} />
                                  <span className="input-radio-label">Male</span>
                                </label>
                              </li>
                              <li className="radio-item">
                                <label className="input-radio">
                                  <input type="radio" name="gender" value="2" onClick={() => setGender(2)} />
                                  <span className="input-radio-label">Female</span>
                                </label>
                              </li>
                              <li className="radio-item">
                                <label className="input-radio">
                                  <input type="radio" name="gender" value="3" onClick={() => setGender(3)} />
                                  <span className="input-radio-label">Other</span>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </form>
                  <button
                    onClick={checkConsents}
                    className="btn bg-branding-green border-0 rounded-pill py-2 mt-3 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                    style={{ width: "100%", height: 48 }}
                  >
                    Pay now
                  </button>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 step-user-details-info mt-5 pt-4 pe-0">
                  <div className="user-details-info bg-branding-1 rounded-4" style={{ padding: "35px 35px 19px", boxShadow: "0 15px 50px -30px #000000" }}>
                    <h3 className="text-light fs-5 mb-4">Summary</h3>
                    <div className="summary-info-impact pb-3">
                      <div className="summary-info-impact_title text-light d-flex align-items-center" style={{ fontSize: 18 }}>
                        {entries} chances to win
                      </div>
                      <div className="summary-info-impact_period lh-1" style={{ color: "rgb(255,255,255,0.7)", paddingLeft: 35 }}>
                        every draw
                      </div>
                    </div>
                    <hr className="bg-light w-100 border-0" style={{ height: 1, opacity: 0.1 }} />
                    <div className="summary-info-price d-flex align-items-center pb-2">
                      <span className="summary-info-price_amount text-light display-4">£ {amount}</span>
                      <span className="summary-info-price_period ps-3" style={{ color: "rgb(255,255,255,0.7)" }}>
                        {isoneoff ? "" : "per month"}
                      </span>
                    </div>
                    <hr className="bg-light w-100 border-0" style={{ height: 1, opacity: 0.1 }} />
                    <p className="summary-info-upgrade-text m-0" style={{ color: "rgb(255,255,255,0.7)" }}>
                      Upgrade or cancel anytime
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        {isStripe ? (
          <Modal show={oneShow} onHide={() => setOneShow(false)} size="md" centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Elements stripe={stripePromise}>
                <StripeForm
                  UserID={UserID}
                  oneoff={isoneoff}
                  show={true}
                  email={userEmail}
                  name={fullName}
                  address1="-"
                  address2="-"
                  postcode="-"
                  amount={amount}
                  entries={entries}
                  hideModal={hideModal}
                  navigate={navigate}
                  timeout={timeout}
                />
              </Elements>
            </Modal.Body>
          </Modal>
        ) : (
          ""
        )}
        <ToastContainer />

        {/* </div> */}
      </section>

      <footer className="footer-section font-face-sh mt-5 position-relative home-footer">
        <div className="container">
          <div className="footer-logo">
            <img className="" src={LogoFoundationGray} alt="logo-foundation-gray" style={{ width: 130 }} />
          </div>
          <div className="row pt-4">
            <div className="col-sm-12 col-md-12 col-lg-5 black-clr">
              <h4 className="mb-4 pb-2 pt-2 font-face-sh-bold">Contact us</h4>
              <div className="footer-address d-flex align-items-start">
                <img className="me-3" src={FooterIconAddress} alt="FooterIconAddress" style={{ width: 24 }} />
                <p style={{ fontSize: 14 }}>Post: PFW Holdings Ltd Suite#300, 4 Blenheim Court, Peppercorn Close, Peterborough, PE1 2DU</p>
              </div>
              <div className="footer-email d-flex align-items-start">
                <img className="me-3" src={FooterIconEmail} alt="FooterIconEmail" style={{ width: 24 }} />
                <p style={{ fontSize: 14 }}>
                  Support:{" "}
                  <a href="mailto:hello@playfundwin.com" className="text-decoration-none black-clr branding-1-hover">
                    hello@playfundwin.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-3 mt-md-5">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6 black-clr mb-3">
                  <p className="mt-4 pt-2 font-face-sh-bold">Follow us</p>
                  <div className="d-flex">
                    <a className="black-clr text-decoration-none" href="https://www.facebook.com/ChelseaFC" target="_blank">
                      <img className="me-3" src={Facebook} alt="Facebook" style={{ width: 24 }} />
                    </a>
                    <a className="black-clr text-decoration-none" href="https://twitter.com/CFCFoundation" target="_blank">
                      <img className="me-3" src={Twitter} alt="Twitter" style={{ width: 24 }} />
                    </a>
                    <a className="black-clr text-decoration-none" href="https://www.instagram.com/chelseafc/" target="_blank">
                      <img className="me-3" src={Instagram} alt="Instagram" style={{ width: 24 }} />
                    </a>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex mt-md-5 pt-2">
                    <img className="me-3" src={Visa} alt="Visa" style={{ width: 64 }} />
                    <img className="me-3" src={Mastercard} alt="Mastercard" style={{ width: 64 }} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-4  mt-md-5 black-clr ps-md-5">
              <p className="mt-4 pt-3" style={{ fontSize: 13 }}>
                Lucky Blues is operated by{" "}
                <a className="text-decoration-none black-clr branding-1-hover" href="https://playfundwin.com/" target="_blank">
                  Play Fund Win
                </a>
              </p>
              <div className="d-flex" style={{ fontSize: 14 }}>
                <Link className="black-clr text-decoration-none pe-5 branding-1-hover font-face-sh-bold" to={"/terms-conditions"}>
                  Terms & Conditions
                </Link>
                <Link className="black-clr text-decoration-none branding-1-hover font-face-sh-bold" to={"/privacy-policy"}>
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
